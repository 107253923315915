/* You can add global styles to this file, and also import other style files */

html, body {
  min-height: 100%;
}

body {
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
}

@import 'front/variable';
@import 'front/theme';
@import 'front/fonts';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';

/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    opacity: 1 !important;
    backdrop-filter: blur(8px);
    background: rgba(5, 46, 57, 0.65) !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #004691 !important;
}

.cdk-drop-list.list {
  border: 1px solid #004691 !important;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left !important;
}

.box-divider {
  width: 100%;
  margin: 30px auto 20px;
}

.datepicker-field {
  .mat-form-field-wrapper {
    height: 80px;

    .mat-datepicker-input,
    .mat-datepicker-toggle {
      top: -11px;
      position: relative;
    }
  }
}

@media screen and (max-width: 1318px) {
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    white-space: nowrap; //no break line
    padding: 0 10px !important;
  }

  .table-coverage {
    th.mat-header-cell {
      padding: 0 !important;
    }
  }
}

.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-sm {
  @media (max-width: 959px) {
    flex-direction: column !important;
  }
}

.flex-column-gt-sm {
  @media (max-width: 1368px) {
    flex-direction: column !important;
  }
}

.flex-column-gt-md {
  @media (max-width: 1465px) {
    flex-direction: column !important;
  }
}

.gap-15 {
  gap: 15px;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.gap-20 {
  gap: 20px;
}

.gap-80 {
  gap: 80px;
}

.gap-45 {
  gap: 45px;
}

.gap-xs-none {
  @media (max-width: 599px) {
    gap: 0;
  }
}

.mw-55 {
  max-width: 55%;
}


.full-width-table {
  width: 100%;
}

button.mat-raised-button {
  span.mat-ripple {
    background: none !important;
  }
}

.btn-lg {
  height: 70%;
  width: 170px;
  color: #004691 !important;
  border-radius: 10px !important;
  @media (max-width: 1444px) {
    height: 50px;
  }
}

.color-primary {
  color: #052E39 !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.mat-title {
  &.faq {
    font-size: 26px;

    strong {
      font-size: 29px;
    }
  }

  &.big {
    font-size: 26px;
  }
}

.justify-center {
  justify-content: center;
}


.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: end;
}

.cursor-pointer {
  cursor: pointer;
}

.background-white {
  background-color: #fff;
}

.color-white {
  color: #fff !important;
}

.justify-between {
  justify-content: space-between !important;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 13px !important;
}

.transform-icon {
  transform: scale(0.8);
}


.transform-icon-like {
  transform: scale(0.6);
}

.w-100 {
  width: 100% !important;
}

.w-100-xs {
  @media (max-width: 599px) {
    width: 100% !important;
  }
}

.w-100-sm {
  @media (max-width: 959px) {
    width: 100% !important;
  }
}

.w-100-md {
  @media (max-width: 1280px) {
    width: 100% !important;
  }
}

.w-50-md {
  @media (max-width: 1280px) {
    width: 50%;
  }
}

.w-100-gt-sm {
  @media (max-width: 1368px) {
    width: 100%;
  }
}

.w-100-lg {
  @media (max-width: 1550px) {
    width: 100%;
  }
}

//.inner-card {
//  overflow: auto;
//  //max-height: 550px;
//}


.max-w-md {
  @media (max-width: 1280px) {
    max-width: 100% !important;
  }
}

.max-w-lg {
  @media (max-width: 1550px) {
    max-width: 100% !important;
  }
}

.gap-10-md {
  @media (max-width: 1280px) {
    gap: 10px;
  }
}

.gap-15-md {
  @media (max-width: 1280px) {
    gap: 15px;
  }
}

.gap-15-sm {
  @media (max-width: 959px) {
    gap: 15px;
  }
}

.gap-30-md {
  @media (max-width: 1280px) {
    gap: 30px;
  }
}

.gap-30-sm {
  @media (max-width: 959px) {
    gap: 30px;
  }
}

.gap-30-lg {
  @media (max-width: 1550px) {
    gap: 30px;
  }
}

.inner-side {
  padding-left: 40px !important;
}


.tab-custom {
  .mat-ripple.mat-tab-label.mat-focus-indicator {
    background-color: #004691 !important;
    color: #EB0028 !important;
    width: 50%;
    height: 50px;
    font-size: 16px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    opacity: 1;

    &.mat-tab-label-active {
      background-color: #EB0028 !important;
      color: #004691 !important;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}

.mat-tab-label-active {
  height: 60px !important;
  font-size: 18px;
  color: #052E39;
  font-weight: bold;
}

.mat-tab-labels {
  align-items: end;
}


.mat-raised-button {
  line-height: 37px !important;
  border-radius: 20px !important;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: $white !important;
  background-color: $secondary;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: $white !important;
  background-color: $primary;
}

.flex-wrap {
  flex-wrap: wrap;
  display: flex;
}

.page_bg-secondary {
  &.bg {
    background-color: #EFF3F8;
  }

  @media (max-width: 962px) {
    padding: calc(5rem / 4) !important;
  }
}

.mat-dialog-container {
  @media (max-width: 962px) {
    max-height: 615px !important;
  }
}

.mat-dialog-title {
  padding: 20px;
  background-color: #516d75;

  h3 {
    color: #ffffff;
  }
}

.show-divider-sm {
  display: none;
  @media (max-width: 959px) {
    display: block !important;
  }
}

.break-all {
  word-break: break-all;
}

.datepicker-extract {
  .mat-ripple {
    background: none !important;
  }
}

.paginator-nocolor {
  .mat-ripple {
    background: none !important;
  }
}

/*.mat-list-item.selected > .mat-list-item-content > a {
  border-radius: 0 !important;
  background-color: #EB0028 !important;
  font-weight: bold !important;

  span {
    color: #ffffff !important;
  }

  svg-icon {
    svg {
      fill: #ffffff !important;
      stroke: #ffffff !important;
    }
  }
}*/

.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}

.text-size-small {
  font-size: 12px;
}

.text-size-regular {
  font-size: 14px;
}

.text-size-middle {
  font-size: 22px;
}

.text-size-large {
  font-size: 28px;
}

.text-size-big {
  font-size: 36px;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-tertiary {
  color: $tertiary;
}

.btn {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  border: 1px solid;
  padding: 10px 35px;
  align-items: center;
  border-radius: 20px;
  justify-content: center;

  &-block {
    width: 100%;
    display: block;
  }

  &-outline {
    &-primary {
      color: $primary;
      border-color: $primary;
      background-color: transparent;
    }

    &-secondary {
      color: $secondary;
      border-color: $secondary;
      background-color: transparent;
    }

    &-white {
      color: $white;
      border-color: $white;
      background-color: transparent;
    }
  }

  &-primary {
    color: $white;
    border-color: $primary;
    background-color: $primary;
  }
}

.delete-btn {
  display: flex;
  height: 40px;
  width: 140px;
  max-width: 80px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #940101;

  svg-icon {
    fill: #940101;

    svg {
      width: 20px;
    }
  }
}

.add-btn {
  svg-icon {
    margin-right: 5px;
  }

  cursor: pointer;
  display: flex;
  height: 40px;
  width: 140px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #004691;

  span {
    font-size: 14px;
    color: #004691;
  }
}


.option {
  background-color: #EFEFEF;
  padding: 11px 16px;

  &.offers {
    padding: 11px 16px 11px 0;
  }

  border-radius: 5px;
  margin: 6px 0;
}

.add-button {
  background-color: #004691;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  height: 36px;
  width: 36px;

  mat-icon {
    margin-left: 1px;
  }
}

.delete {
  background-color: #EB0028;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.eye {
  background-color: #FAB932;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.view {
  background-color: #7FA2C8;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.duplicate {
  background-color: #7FA2C8;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.edit {
  background-color: #004691;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;

  svg-icon {
    svg {
      fill: #ffffff !important;
    }
  }
}

.edit-circle {
  svg-icon {
    svg {
      fill: #004691 !important;
    }

  }

  background-color: #FAB932;
  padding: 1px 7px;
  border-radius: 100%;

}

.delete-circle {
  background-color: #EB0028;
  padding: 1px 7px;
  border-radius: 100%;
}

.duplicate-circle {
  background-color: #DFDFDF;
  padding: 1px 7px;
  border-radius: 100%;
}

.option-circle {
  background-color: #DFDFDF;
  padding: 1px 7px;
  border-radius: 100%;
}

.svg-back {
  svg {
    transform: rotate(90deg);
  }
}

.svg-white {
  svg {
    // stroke: #ffffff !important;
    fill: #FFFFFF !important;
  }
}

.svg-red {
  svg {
    fill: #EB0728 !important;
  }
}

.svg-primary {
  svg {
    fill: $primary !important;
  }
}

.svg-secondary {
  svg {
    fill: $secondary !important;
  }
}

.svg-tertiary {
  svg {
    fill: $tertiary !important;
  }
}

.dialog-card {
  width: 100%;
  padding: 5px;
  border-radius: 15px;
  background-color: #EFF3F8;
}

.cdk-overlay-pane {
  //width: 66vw !important;
  overflow: auto;
  @media (max-width: 600px) {
    width: 95vw !important;
  }
}


.dialog-subtitle {
  color: #848484;
  font-weight: bold;
}

.logo-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  min-width: 300px;
  height: 300px;
  background-color: #ffffff;
  align-items: center !important;
  justify-content: center !important;
}

.logo-card-small {
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;
  width: 110px;
  height: 60px;
  background-color: #ffffff;

  &.user-highlight {
    width: 70px;
    height: 70px;
    margin-bottom: 0;
  }

  .img-card {
    height: 100% !important;
  }

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.logo-card-medium {
  padding: 5px;
  border-radius: 5px;
  width: 200px;
  height: 60px;
  background-color: #ffffff;

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
  }
}

.logo-card-big {
  padding: 5px;
  border-radius: 5px;
  width: 305px;
  height: 165px;
  background-color: #ffffff;
  margin-bottom: 30px;
  justify-content: center;

  &.highlight {
    width: 200px !important;
    min-height: 300px !important;
  }

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
  }
}


.img-card {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #DFDFDF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.user-highlight {
    height: 100%;
    width: 100%;
  }

  &.highlight-content {
    height: 90%;
  }

  span {
    color: #848484;
    font-weight: bold;
  }
}

.cancel-button {
  width: 120px;
  height: 45px;
  background-color: transparent !important;
  border: 2px solid #004691 !important;
  color: #004691 !important;
}

.save-button {
  width: 120px;
  height: 60px;
  background-color: #004691 !important;
  border: 2px solid #004691 !important;
  color: #ffffff !important;
}


.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  //color: #ffffff !important;
  border-radius: 10px !important;

}

.mat-form-field-appearance-outline .mat-form-field-outline {
  //color: #ffffff !important;
  border-radius: 10px !important;
}

.divider-row {
  width: 100%;
  height: 1px;
  background-color: #004691;
  margin-top: 10px;
  margin-bottom: 20px;

  &.white {
    background-color: #fff !important;
  }
}

.mat-elevation-z8 {
  box-shadow: none !important;
  overflow-x: auto;
  width: 100%;
}

table.mat-table {
  border-collapse: initial;
  border-spacing: 0;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

mat-paginator {
  border-radius: 0 0 15px 15px;
}

#snav .mat-list-item a {
  margin-bottom: 0 !important;
}

.mat-divider {
  border-top-color: #014691 !important;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.th-no-padding {
  padding: 0;
  @media (max-width: 1320px) {
    padding: 0 !important;
  }
}


th.mat-header-cell {
  white-space: nowrap;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

.align-items-start {
  align-items: start;
}

.tab-card {
  &-top {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: end;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
      border-radius: 20px 20px 0 0;
      background-color: #EAEAEA;
      width: 50%;
      height: 50px;

      span {
        color: #014691;
      }

      &.active {
        height: 70px;
        padding: 20px;

        span {
          font-weight: bold;
          color: #ffffff;
        }

        svg-icon {
          svg {
            fill: #FAB932 !important;
            // stroke: #FAB932 !important;
          }
        }

        background-color: #014691;
      }
    }
  }

  &-content {
    background-color: #ffffff;
    //padding: 20px 20px 0 20px;
  }
}

.treat-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.is-cursor {
  cursor: pointer;
}

.table-hide-button {
  &-item {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .hover-buttons:hover {
    .table-hide-button-item {
      opacity: 1;
      visibility: visible;
    }
  }
}

.high-hide-button {
  &-item {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  .hover-buttons:hover {
    .high-hide-button-item {
      opacity: 1;
      visibility: visible;
    }
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.campaign-inner {
  &.dark {
    background-color: #DFDFDF;
  }

  padding-right: 100px;
  padding-left: 100px;
  @media(max-width: 800px) {
    padding: 0
  }
}


.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;

    &-service {
      margin: 1px 5px 0;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.circle-radius {
  border-radius: 50%;
}

.high-hide-button {
  .edit,
  .delete,
  .duplicate-move,
  .option,
  .add-btn,
  .view,
  .eye {
    transition: transform 0.5s ease;
  }

  .add-btn {
    transform: translateX(85px);
  }

  &:hover {
    .eye {
      transform: translateX(-20px);
    }

    .edit {
      transform: translateX(-15px);
    }

    .duplicate-move {
      transform: translateX(-15px);
    }

    .delete {
      transform: translateX(-10px);
    }

    .add-btn {
      transform: translateX(-20px);
    }

    .view {
      transform: translateX(-20px);
    }
  }
}

.outline-button {
  background-color: transparent !important;
  border: 2px solid #004691 !important;
  color: #004691 !important;
}

.cdk-overlay-pane {
  //min-height: 380px !important;
  //overflow-y: auto;
}

.overlay-scroll {
  height: 50vh !important;
  overflow: auto !important;
}

.overlay-scroll-max {
  max-height: 50vh !important;
  overflow: auto !important;
}

.disabled-click {
  pointer-events: none;

  .delete {
    background-color: #EFEFEF;
  }
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-black {
  background-color: #080808 !important;
}

.bg-gray {
  background-color: #EFF3F8 !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.w-50 {
  width: 50%;
}

.radius-bottom-right-10 {
  border-radius: 0 0 10px 0 !important;
}

.radius-bottom-left-10 {
  border-radius: 0 0 0 10px !important;
}

.radius-top-right-10 {
  border-radius: 0 10px 0 0 !important;
}

.radius-top-left-10 {
  border-radius: 10px 0 0 0 !important;
}

.radius-top-10 {
  border-radius: 10px 10px 0 0 !important;
}

.radius-bottom-10 {
  border-radius: 0 0 10px 10px !important;
}

.text-align-end {
  text-align: end;
}

.filter-gray-bg {
  filter: contrast(75%) brightness(115%);
}

.dark-mode-bg {
  background-color: #030722;
}

.z-index-1 {
  z-index: 1;
}

.focus-offer {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  height: 52px;
  align-items: center;

  span {
    font-weight: bold;
    color: #014691;
  }

  @media(max-width: 700px) {
    margin-bottom: 10px;
  }
}

.mat-tab-body-wrapper {
  .mat-tab-body-content {
    padding-top: 30px;
    overflow-x: hidden;
  }
}

button:disabled,
[disabled] {
  opacity: 0.5;
}

.slick-prev:before, .slick-next:before {
  color: $secondary !important;
}

.slick-prev, .slick-next {
  top: 30% !important;
}

/*.slick-dots{
  li{
    width: 40px !important;
    button{
      width: 40px !important;
      &:before{
        width: 40px !important;
      }
    }
  }
}*/

/* Ocultar os dots padrão */
.slick-dots li button:before {
  display: none;
}

.slick-dots {
  display: flex;
  justify-content: center;
}

.slick-dots li {
  width: 25px !important;
  height: 5px !important;
  margin: 0 0px !important;
  animation: margin 0.2s ease;
}

.slick-dots li.slick-active {
  margin: 0 6px 0 0 !important;

  .slick-dot-bar {
    width: 100%;
    background-color: $tertiary;
    animation: progressSlickDots 0.2s linear;
  }
}

.dots-primary .slick-dots li.slick-active .slick-dot-bar {
  background-color: $primary;
}

.slick-dots li .slick-dot-bar {
  background-color: #ccc;
  border-radius: 10px;
  width: 70%;
  height: 100%;
  animation: width 0.2s ease;
}

@keyframes progressSlickDots {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


.slick-slide {
  margin: 0 27px;
}

.slick-list {
  margin: 0 -27px;
}

.slick-track {
  margin-left: 0 !important;
}

.offers {
  .offers-top > div {
    @media (max-width: 1465px) {
      gap: 12px;
      width: 100% !important;
      align-items: center !important;
      justify-content: center;
    }
  }

  .manage {
    justify-content: center;
  }
}

.filter-option {
  &[disabled] {
    opacity: 1 !important;
  }

  .mat-pseudo-checkbox {
    opacity: 0 !important;
  }
}

.page-content {
  margin: 0 auto;
  max-width: 1280px;

  @media(max-width: 1440px) {
    max-width: 1080px;
  }

  &.min-h {
    min-height: 45vh;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 0 5px;
  }
}

.list-thumbs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  &-item {
    width: 25%;
    text-align: center;
  }

  &-label {
    width: 100%;
    height: 30px;
    padding: 0 1px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.sc-vrqbdz-8.eLaznq {
  display: none;
}

.hero {


  @media(max-width: 1080px) {
    margin: 64px 0 0 0;
  }

  .slick-dots {
    bottom: 10px;
    max-width: 1300px;
    text-align: right;

    @media(min-width: 1440px) {
      max-width: 1580px;
    }

    @media(min-width: 1080px) {
      bottom: 180px;
    }

    li .slick-dot-bar {
      background-color: $white;
    }

    .slick-active {
      .slick-dot-bar {
        background-color: $tertiary;
      }
    }
  }
}

.wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;

  .page-custom-top {
    margin: -200px auto 0;

    @media(max-width: 1445px) {
      margin: -160px auto 0;
    }

    @media(max-width: 1080px) {
      margin: -30px auto 0;
    }
  }
}

.card {
  padding: 20px;
  margin: 10px;
  border-radius: 14px;
  background-color: $white;

  &.dark {
    color: $white;
    background-color: rgba(5, 46, 57, 0.7);
    &.ranking{
      margin: 10px 0;
    }

    &.full-fill {
      background-color: $primary;
    }
  }

  &-header {
    margin-bottom: 0;

    &-status {
      display: block;
      margin-top: 10px;
      color: $primary;

      &.pending {
        color: $warning;
      }

      &.reproved {
        color: $danger;
      }

      &.approved {
        color: $success;
      }
    }

    &-title {
      color: $primary;
      font-size: 21px;
      font-weight: bold;
      margin: 0;
    }

    &-subtitle {
      color: $primary;
      font-size: 16px;
      margin: -8px 0 0 0;
    }
  }

  &-body {
    color: $primary;

    &.ranking-body{
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-content{
      &.w-padding{
        padding: 25px 0;
      }
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }

  &-thumbnail {
    padding: 0;
    min-height: 100%;

    &-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &-box {
      padding: 0 20px 5px;
    }
  }

  &-panel {
    width: 90%;
    max-width: 650px;
    margin: 20px auto;

    @media(max-width: 1080px) {
      width: 100%;
      padding: 0 10px;
    }

    &-item {
      display: flex;
      padding: 20px;
      margin: 20px 0;
      border-radius: 10px;
      flex-direction: column;
      background-color: $white;
    }

    &-header {
      display: flex;
      justify-content: space-between;

      &-thumb {
        width: 50px;
        height: 50px;
        margin: 0 15px 0 0;
        border-radius: 50px;
      }

      &-info {
        flex-grow: 1;

        &-title {
          font-size: 14px;
          color: $secondary;
          font-weight: bold;
        }

        &-label {
          font-size: 12px;
        }
      }
    }

    &-body {
      font-size: 14px;
    }

    &-footer {
      display: flex;
      padding: 20px 0 0 0;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.like-group,
.comment-group {
  display: flex;
  font-size: 13px;
  align-items: center;

  svg {
    margin: 0 7px 0 0;
  }
}

.panel-expanded {
  max-width: 380px;
  min-width: 300px;
  width: 40%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: $white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transform: translate(-100%, 0);

  @media(max-width: 780px) {
    min-width: 100%;
  }

  &.show {
    transform: translate(0, 0);
  }

  &-header {
    padding: 25px 15px;
    background-color: $gray-light;

    &.dark {
      color: $white;
      background-color: $primary;

      svg {
        fill: $white;
      }

      .panel-expanded-header-title {
        margin: 0 0 0 0;
        color: $white;
      }
    }
  }

  &-body {
    overflow: auto;
    height: 75%;
  }

  &-footer {
    display: flex;
    padding: 20px;
    margin: 5px 0 0 0;
    align-items: center;
    border-top: 1px solid $primary;
    justify-content: space-between;

    &-link {
      color: $primary;
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.page-inside {
  &-hero {
    width: 100%;
    display: block;
    min-height: 400px;
    background-repeat: repeat-x;
    background-position: top left;
    background-image: url("/assets/images/page-inside-header-bg.png");

    &.hero-image {
      min-height: 500px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &-gradient {
        &:after {
          content: '';
          display: block;
          width: 100%;
          min-height: inherit;
          background: linear-gradient(0deg, #F0F0F0 0%, rgba(0, 0, 0, 0.5) 60%);
        }
      }
    }

    &.hero-empty {
      min-height: 480px;
    }
  }

  &-container {
    display: block;
    position: relative;
    margin: -250px 0 0 0;

    &.container-image {
      margin: -350px 0 0 0;
    }
  }

  &-header {
    color: $white;

    @media (max-width: 1080px) {
      padding: 0 10px;
    }

    &-title {
      font-size: 45px;
      font-weight: bold;
      margin: 0 0 40px 10px;

      @media (max-width: 1080px) {
        font-size: 35px;
      }
    }

    &-description {
      font-weight: normal;
      max-width: 384px;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      color: #f0f0f0;
      //margin: 0 0 40px 10px;
    }

    &-row {
      display: flex;
      justify-content: space-between;

      @media (max-width: 780px) {
        flex-direction: column;
      }
    }

    &-col {
      &.col-title {
        min-width: 35%;
      }
    }

    &-back {
      top: 0px;
      left: -80px;
      position: absolute;

      @media (max-width: 1280px) {
        top: 0px;
        left: 0px;
        position: relative;
        transform: scale(0.8) translate(0px, -13px);
      }
    }
  }

  &-body {
  }
}

.breadcumbs {
  width: 100%;
  display: flex;
  padding: 10px 5px;
  max-width: 1280px;
  align-content: center;
  margin: 20px auto 40px;
  border-bottom: 1px solid $white;

  @media (max-width: 1440px) {
    max-width: 1080px;
  }

  @media (max-width: 1080px) {
    width: 96%;
  }

  &-item {
    color: $white;
    display: flex;
    margin: 0 15px 0 0;
    padding: 0 15px 0 0;
    align-content: center;
    border-right: 1px solid $white;

    &:last-child {
      border-right: none;
    }
  }

  &-ico {
    display: flex;
    align-items: center;
    margin: 0 5px 0 0;

    svg {
      fill: $white;
    }
  }

  &-label {
    color: $white;
    display: flex;
    align-items: center;

    @media (max-width: 1080px) {
      font-size: 12px;
    }
  }
}

.campaign {
  &-grid {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1080px) {
      flex-direction: column;
    }

    .kingdom-beasts {
      //width: 100%;
      max-width: 400px;
      text-align: center;

      @media (max-width: 1080px) {
        max-width: 100%;
      }

      .card-kingdom {
        min-height: auto;
      }

      .kingdom-info {
        padding: 10px 40px;
        text-align: left;

        &.graphic {
          padding: 0;
        }
      }
    }
  }

  &-last {
    margin: 10px;
    flex-grow: 1;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    position: relative;

    @media (max-width: 1080px) {
      min-height: 400px;
    }

    &-image {
    }

    &-container {
      top: 5%;
      left: 3%;
      width: 94%;
      height: 90%;
      position: absolute;
      display: flex;
      border: 4px solid $white;
      border-radius: 10px;

      &-info {
        color: $white;
        margin: 40px;

        &-label {
          font-size: 22px;
          margin: 0 0 10px 0;
        }

        &-title {
          font-size: 45px;
          margin: 0 0 0 0;
        }
      }
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: space-between;

      @media (max-width: 1080px) {
        justify-content: end;
        flex-direction: column;
      }
    }

    &-col {
    }

    &-btn {
      margin: 40px;
      font-size: 16px;
      color: $primary;
      cursor: pointer;
      padding: 10px 25px;
      font-weight: bold;
      border-radius: 20px;
      border: 2px solid $white;
      background-color: rgba(255, 255, 255, 0.6);

      @media (max-width: 1080px) {
        margin: 0 40px 40px;
      }
    }
  }

  &-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;

    &-item {
      width: calc(33.333% - 20px);
      margin-bottom: 20px;

      @media (max-width: 1080px) {
        width: calc(50% - 20px);
      }

      @media (max-width: 780px) {
        width: 100%;
      }
    }
  }
}


.page-highlights {
  margin: 10px;
  flex-grow: 1;
  position: relative;

  &-image {
    width: 60%;
    height: 470px;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    @media(max-width: 1080px) {
      width: 100%;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .card-panel {
    margin: 0;
    width: 100%;
    padding: 0 0;
    display: flex;
    max-width: 100%;
    border-radius: 10px;
    align-items: stretch;
    background-color: $white;
    justify-content: space-between;

    @media(max-width: 1080px) {
      flex-direction: column;
    }

    &-item {
      margin: 0;
      width: 50%;
      align-items: stretch;
      justify-content: space-between;

      @media(max-width: 1080px) {
        width: 100%;
      }
    }
  }
}

.text-regulation {
  font-size: 14px;
  margin-top: 40px;

  &-title {
    color: $primary;
    font-size: 20px;
    text-align: center;
    margin: 20px 0 35px;
  }

  h3 {
    font-size: 16px;
    margin: 25px 0 10px 0;
  }
}

.mat-form-field-flex .mat-form-field-infix {
  display: flex !important;
}

.user-profile-thumb {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: block;
  border-radius: 150px;
}

.user-noPhoto {
  width: 100px;
  height: 100px;
}

.mat-dialog-container {
  overflow: hidden !important;
}

.font-standard {
  font-size: 18px;
}

.overflow-auto {
  overflow: auto !important;
}

.cdk-overlay-pane {
  @media (max-width: 1280px) {
    overflow: auto;
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-25 {
  width: 25%;
}

.gap-30 {
  gap: 30px;
}

.min-h-full {
  min-height: 100% !important;
}


.mat-list-item-content {
  width: 100% !important;
}

.mat-list-base {
  padding: 0 !important;
}

.text-uppercase {
  text-transform: capitalize;
}

.custom-progress-bar {
  overflow: hidden !important;
  height: 19px !important;
  border-radius: 20px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12aedd", endColorstr="#052e39", GradientType=1);
  border: 3px solid #E0E0E0;
}

.custom-progress-bar .mat-progress-bar-fill::after {
  background: linear-gradient(90deg, rgb(18, 174, 221) 0%, rgb(5, 46, 57) 55%) !important;
  border-radius: 20px;
  transition: none;
}

.mat-progress-bar-buffer {
  background-color: #E0E0E0 !important;
}
